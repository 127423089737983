<template>
  <div>

    <HeaderSpacer/>

    <v-container class="mt-10">

      <h1 class="mt-7">Ultime uscite</h1>

      <v-row class="mt-7">
        <v-col v-for="(show,i) in shows" 
               :key="i"
               :cols="cols">
          <ShowCard :show="show"/>
        </v-col>
      </v-row>

      <v-row class="my-7"
             v-if="_paginationHasMore">
        <v-col class="text-center">
          <v-btn text 
                 :loading="isLoading"
                 @click="load">
            <v-icon>mdi-chevron-down</v-icon>
            Carica altri
          </v-btn>
        </v-col>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import pagination from '@/mixins/pagination'
import utility from '@/mixins/utility'

import API from '@/api'

import HeaderSpacer from '@/components/UI/HeaderSpacer'
import ShowCard from '@/components/show/Card'

import { mapMutations } from 'vuex'

export default {
  
  name: 'Watch',

  mixins: [ pagination, utility ],

  components: {
    HeaderSpacer,
    ShowCard
  },

  data: () => ({
    isLoading: false,
    shows: []
  }),

  computed: 
  {
    cols()
    {
      return this.isMobile ? 6 : 3
    }
  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    load()
    {
      this.isLoading = true

      this.setLoading(true)

      let page = this._getNextPage()

      API.shows.latest({ 'filter[not_episodes]':1, page })
               .then(({ data })=>{

                let shows = this.mapModels(data.data)

                this.shows = this.shows.concat(shows)

                this._updatePagination(data.meta)

                this.setLoading(false)

               })
               .finally(()=>{
                this.isLoading = false
               })
    }
  },

  mounted()
  {
    this.load()
  }

}
</script>
