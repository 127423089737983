<template>
  <v-container>

    <HeaderSpacer/>

    <h1 class="mt-7">{{ genre.name }}</h1>

    <v-row class="mt-7">
      <v-col v-for="(show,i) in shows" 
             :key="i"
             cols="3">
        <ShowCard :show="show"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import API from '@/api'

import slugMixin from '@/mixins/slug'
import utility from '@/mixins/utility'

import HeaderSpacer from '@/components/UI/HeaderSpacer'
import ShowCard from '@/components/show/Card'

export default {
  
  name: 'Genre',

  mixins: [ slugMixin, utility ],

  components: {
    HeaderSpacer,
    ShowCard
  },

  data: () => ({
     genre: {}
  }),

  computed: {
    shows()
    {
      return this.genre.shows
    }
  },

  methods:
  {
    load()
    {
      API.genres.one(this.slug)
                .then((res)=>{
                  this.genre = this.mapModel(res.data.data, 'genre')
                })
    }
  },

  mounted()
  {
    this.load()
  }

}
</script>
