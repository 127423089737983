import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout.vue'

import goTo from 'vuetify/lib/services/goto'

// Pages.
import About from '@/views/About.vue'
import Account from '@/views/Account.vue'
import Cast from '@/views/Cast.vue'
import Collection from '@/views/Collection.vue'
import EmailVerification from '@/views/EmailVerification.vue'
import Genre from '@/views/Genre.vue'
import Help from '@/views/Help.vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Privacy from '@/views/Privacy.vue'
import Search from '@/views/Search.vue'
import Show from '@/views/Show.vue'
import SignupStore from '@/views/SignupStore.vue'
import Tag from '@/views/Tag.vue'
import Terms from '@/views/Terms.vue'
import Trailer from '@/views/Trailer.vue'
import Video from '@/views/Video.vue'
import Watch from '@/views/Watch.vue'
import Watchlist from '@/views/Watchlist.vue'

import API from '@/api'

Vue.use(VueRouter)

export const routes = [
  {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
          onlyNotLogged: true,
          sitemap:true,
    },
  },
  {
      path: '/signup',
      name: 'signup',
      component: SignupStore,
      meta: {
        onlyNotLogged: true,
        sitemap: true,
      }
  },
  {
      path: '/email-verification',
      name: 'emailVerification',
      component: EmailVerification,
      meta: {
        onlyNotLogged: true
      }
  },
  {
      path: '/video/:videoHash',
      name: 'video',
      component: Video
  },
  {
      path: '/trailer/:slug',
      name: 'trailer',
      component: Trailer
  },
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children: [
        {
            path: 'home',
            name: 'home',
            component: Home,
            meta: {
                sitemap: {
                    lastUpdate: new Date()
                    },
            }
        },
        {
            path: 'account',
            name: 'account',
            component: Account,
            meta: {
                onlyLogged: true
            },
        },
        {
            path: 'search',
            name: 'search',
            component: Search,
            meta:{
                sitemap:{
                    lastUpdate: new Date()
                    }
            }
        },
        {
            path: 'watch',
            name: 'watch',
            component: Watch,
            meta:{
                sitemap:{
                        lastUpdate: () => new Date("1985")
                    }
            }
        },
        {
            path: 'show/:slug',
            name: 'show',
            component: Show,
            meta:{
                sitemap: {
                    api: API.shows.list,
                    needsFetch: true,
                    lastUpdate: new Date(),
                    reducer: (data) => {
                        //PASSARE LAST DATE
                        return data
                    }
                }
            }
        },
        {
            path: 'cast/:slug',
            name: 'cast',
            component: Cast,
            meta:{
                sitemap:{
                    needsFetch: true,
                    api: API.casts.list,
                    lastUpdate: new Date()
                }
            }
        },
        {
            path: 'genre/:slug',
            name: 'genre',
            component: Genre,
            meta:{
                sitemap:{
                    needsFetch: true,
                    api: API.genres.list,
                    lastUpdate: new Date()
                }
            }
        },
        {
            path: 'collection/:slug',
            name: 'collection',
            component: Collection
        },
        {
            path: 'tag/:slug',
            name: 'tag',
            component: Tag
        },
        {
            path: 'watchlist',
            name: 'watchlist',
            component: Watchlist,
            meta: {
                onlyLogged: true
            }
        },
        // Static pages.
        {
            path: 'about',
            name: 'about',
            component: About
        },
        {
            path: 'terms',
            name: 'terms',
            component: Terms
        },
        {
            path: 'privacy',
            name: 'privacy',
            component: Privacy
        },
        {
            path: 'help',
            name: 'help',
            component: Help
        }
      ]
  }
]

const scrollBehavior = (to)=>{
    if (to.hash)
        return goTo(to.hash)
    
    return goTo(0)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

router.beforeEach((to, from, next) => {

  const loggedIn = localStorage.getItem('user')

  // auth check
  if (to.matched.some(record => record.meta.onlyLogged) && !loggedIn) {
    next('/login')
    return
  }

  if (to.matched.some(record => record.meta.onlyNotLogged) && loggedIn) {
    next('/home')
    return
  }

  next()

})

export default router
