<template>
  <div>
    <!-- CookieYES -->
  </div>
</template>

<style lang="scss" scoped>
  
</style>

<script>
import SETTINGS from '@/plugins/settings'

  export default {
    name: 'CookieYes',

    mixins: [],

    data: () => ({
      
    }),

    components: {

    },

    props: {
      
    },

    methods:
    {
      appendScript()
      {
        const token = SETTINGS.COOKIE_YES,
            url = `https://cdn-cookieyes.com/client_data/${token}/script.js`

        if( !token )
          return

        let script = document.createElement('script')
            script.setAttribute('src', url)
            script.setAttribute('id', 'cookieyes')

        document.body.appendChild(script)
      }
    },

    mounted()
    {
      this.appendScript()
    }
  }
</script>
