import utility from '@/mixins/utility'

class Collection {

  #data = {}

  #shows = []

  constructor(collection={})
  {  
    this.#data = collection

    this.#shows = utility.methods.mapModels(collection.shows)
  }

  /**
   * Property getters.
   */
  
  get id()
  {
    return this.#data.id
  }

  get slug()
  {
    return this.#data.slug
  }

  get featured()
  {
    return this.#data.featured == true
  }

  get layout()
  {
    return this.#data.layout || 'cover'
  }

  get name()
  {
    return this.#data.name
  }

  get shows()
  {
    return this.#shows
  }

  /**
   * Computed properties.
   */

  get isEmpty()
  {
    return this.shows.length == 0
  }

  get isNotEmpty()
  {
    return this.shows.length != 0
  }

  /**
   * Methods.
   */

  layoutIs(layout)
  {
    let current = this.layout || 'card'

    return current.toLowerCase() === layout.toLowerCase()
  }
}

export default Collection