<template>
  <!-- Log-in  -->
    <section class="position-relative pb-0">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">

                        <p class="mb-10">
                            <Logo white :height="70"/>
                        </p>

                        <v-form name="pms_login" 
                              id="pms_login"
                              :disabled="isLoading">

                            <v-alert type="info"
                                     v-if="requestMessage"
                                     class="mb-10">
                                {{ requestMessage }}
                            </v-alert>

                            <h4>Accedi</h4>
                            
                            <v-text-field 
                                    outlined
                                    type="email"
                                    label="Indirizzo email"
                                    v-model="credentials.email"
                                    :autofocus="true"
                                    class="transparent"
                                    @keyup.enter="login"/>

                            <v-text-field 
                                    outlined
                                    :type="showPassword?'text':'password'"
                                    label="Password"
                                    v-model="credentials.password"
                                    class="mb-0 transparent"
                                    :append-icon="showPassword?'mdi-eye-off':'mdi-eye'"
                                    @click:append="showPassword=!showPassword"
                                    @keyup.enter="login"/>

                            <p class="login-submit text-center my-5">
                                <v-btn tile x-large color="primary"
                                    @click="login"
                                    :disabled="!canSubmit">
                                  Login
                                </v-btn>
                            </p>
                            
                            <p class="text-center mt-10">
                              <v-btn @click="goTo('signup')" text>
                                Non hai un account? Registrati!
                              </v-btn>
                            </p>

                            <p class="text-center">
                              <v-btn @click="getPassword" text>
                                Non ricordi la password del tuo account?
                              </v-btn>
                            </p>

                            <Loading :status="isLoading"/>
                            
                        </v-form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Log-in  -->
</template>

<script>
import _ from 'lodash'
import utility from '@/mixins/utility'

import { mapMutations } from 'vuex'

import Loading from '@/components/UI/Loading'
import Logo from '@/components/UI/Logo'

import ZWAN_STORE from '@/plugins/zwanStore'

export default {
  
  name: 'Login',

  mixins: [utility],

  components: {
    Loading,
    Logo
  },

  data: () => ({
    credentials: {
        email: null,
        password: null
    },
    isLoading: false,
    showPassword: false
  }),

  computed:
  {
    canSubmit()
    {
        return ( this.credentials.email && 
                 this.credentials.password )
    },

    requestMessage()
    {
        let messageCode = this.$route.query.message

        return messageCode ? this.$t(`login.messages.${messageCode}`) : null
    }
  },

  methods:
  {
    ...mapMutations({
      growl: 'ui/growl'
    }),

    login()
    {
        if( !this.canSubmit )
            return

        this.isLoading = true
    
        this.$store.dispatch('login', this.credentials)
                   .then(()=>{
                    this.goTo('home')
                   })
                   .catch((err)=>{
                    // something went wrong here!
                    let message = err.response.data.message

                    if( _.isArray(message) )
                        message = message[0]

                    this.growl({
                        content: this.$t(message),
                        color: 'warning'
                    })

                   })
                   .finally(()=>{

                    this.isLoading = false

                   })
    },

    getPassword()
    {
        const url = ZWAN_STORE.url('reset-password')

        location.href = url
    }

  },

  mounted()
  {

  }

}
</script>
