<template>

	<div>

		<Header @drower="$refs.mobileMenu.toggle()"/>

    <MobileMenu ref="mobileMenu" v-if="isMobile"/>
  
		<router-view/>

		<Footer v-if="!isEmbed"/>
		
	</div>
  
</template>

<script>
import Footer from '@/components/UI/Footer'
import Header from '@/components/UI/Header'
import MobileMenu from '@/components/UI/MobileMenu'

import utility from '@/mixins/utility'

import { mapGetters } from 'vuex'

export default {
  name: 'App',

  mixins: [utility],

  components: {
    Footer,
    Header,
    MobileMenu
  },

  data: () => ({
    //
  }),

  computed:
  {
    ...mapGetters({
      isEmbed: 'spaces/isEmbed'
    }),
  },

  mounted()
  {
    if( this.$route.name === 'layout' )
      this.goTo('home')
  },

  watch: {}
};
</script>
