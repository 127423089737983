<template>
  <div>

    <HeaderSpacer/>

    <SearchBar :search.sync="theSearch"
               :loading="isLoading"/>

    <v-container class="mt-10">

      <v-alert type="info" v-if="noResults">
        Non ci sono risultati per questa ricerca.
      </v-alert>

      <h1 class="mt-7">
        {{ resultsHeading }}
      </h1>

      <v-row class="mt-7" v-if="shows.length && theSearch">
        <v-col v-for="(show,i) in shows" 
               :key="i"
               cols="3">
          <ShowCard :show="show"/>
        </v-col>
      </v-row>

      <v-row class="mt-7" v-else>
        <v-col v-for="(show,i) in defaultShows" 
               :key="i"
               :cols="isMobile ? 6 : 3">
          <ShowCard :show="show"/>
        </v-col>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import API from '@/api'

import liveTyping from '@/mixins/liveTyping'
import utility from '@/mixins/utility'

import HeaderSpacer from '@/components/UI/HeaderSpacer'
import SearchBar from '@/components/UI/SearchBar'
import ShowCard from '@/components/show/Card'

import { mapMutations } from 'vuex'

export default {
  
  name: 'Search',

  mixins: [ liveTyping, utility ],

  components: {
    HeaderSpacer,
    SearchBar,
    ShowCard
  },

  data: () => ({
    defaultShows: [],
     isLoading: false,
     theSearch: '',
     shows: []
  }),

  computed: {
    noResults()
    {
      return (this.theSearch && !this.shows.length)
    },
    resultsHeading()
    {
      if( this.noResults )
        return 'Potrebbe interessarti...'

      else if( this.theSearch )
        return 'Risultati della ricerca'

      return 'Guarda online'
    }
  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    doSearch(search)
    {
      this.isLoading = true

      // utilizzo il livetyping prima di inviare la richiesta API
      let resolve = () => {

        // Google Analytics.
        this.$gtag.event('search', {
          'event_category': 'search',
          'event_label': 'search',
          'value': search
        })

        API.shows.list({ 'filter[title]':search })
                 .then((res)=>{

                  this.shows = this.mapModels(res.data.data)

                  this.isLoading = false

                 })
          
      }

      this.liveType(resolve)

    },
    loadDefaults()
    {
      this.setLoading(true)

      API.shows.latest({ 'filter[not_episodes]':1 })
               .then((res)=>{

                this.defaultShows = this.mapModels(res.data.data)

                this.setLoading(false)

               })
    }
  },

  mounted()
  {
    this.loadDefaults()
  },

  watch:
  {
    theSearch(newValue)
    {
      this.doSearch(newValue)
    }
  }

}
</script>
