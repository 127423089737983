const state = {

    growler: {},

    isLoading: false,

}

const getters = {

    growler: state => state.growler,

    isLoading: state => state.isLoading,

}

const actions = {



}

const mutations = {

    growl (state, data) {
      /**
       * @todo import from config .js
       */
      const defaults = {
        color: 'grey',
        status: true,
        content: ''
      }

      if( typeof data == 'string' )
        data = { content: data }
      
      state.growler = { 
        ...defaults,
        ...data 
      }

    },

    setLoading (state, status = true) {
      state.isLoading = status
    },

}

const ui = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default ui