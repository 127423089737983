<template>
  <video-player  class="video-player-box"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"

                 @play="onPlayerPlay($event)"
                 @pause="onPlayerPause($event)"
                 @ended="onPlayerEnded($event)"
                 @waiting="onPlayerWaiting($event)"
                 @playing="onPlayerPlaying($event)"
                 @loadeddata="onPlayerLoadedData($event)"
                 @timeupdate="onPlayerTimeUpdate($event)"
                 @canplay="onPlayerCanPlay($event)"
                 @canplaythrough="onPlayerCanplayThrough($event)"

                 @statechanged="playerStateChanged($event)"
                 @ready="playerReadied">
  </video-player>
</template>

<style lang="scss">
  .video-player-box {
    & > .video-js {
      
      width: 100vw;
      height: 100vh;

      // control bar
      & .vjs-control-bar {
        font-size: 20px;
      }

      // play icon (disabled)
      &.vjs-paused_ {
        &::after {
          cursor: pointer;
          content: '';
          display: block;
          position: absolute;
          left: 50vw;
          top: 50vh;
          margin-top: -50px;
          margin-left: -50px;
          width: 100px;
          height: 100px;
          background-image: url(~@/assets/images/icons/play-flat.png);
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

    }
  }
</style>

<script>
  // require styles
  import 'video.js/dist/video-js.css'

  import { videoPlayer } from 'vue-video-player'

  export default {
    name: 'VideoPlayer',

    components: {
      videoPlayer
    },

    data: () => ({
      src: null
    }),

    computed:
    {
      player()
      {
        return this.$refs.videoPlayer.player
      },
      playerOptions()
      {
        let src = this.trailer ? this.show.trailer : this.show.video

        /**
         * @see https://docs.videojs.com/tutorial-options.html
         */
        return {
          // videojs options
          autoplay: true,
          muted: false,
          language: 'it',
          liveui: true,
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: src
          }],
          poster: this.show.bg,
        }
      }
    },

    props:
    {
      show: {
        type: Object,
        default: ()=>({})
      },
      trailer: {
        type: Boolean,
        default: false
      }
    },

    methods:
    {
      // listen event
      onPlayerPlay(player)
      {
        console.log('player play!', player)
      },

      onPlayerPlaying(player)
      {
        console.log(player)
      },

      onPlayerPause(player)
      {
        console.log('player pause!', player)
      },
      // ...player event

      // or listen state event
      playerStateChanged(playerCurrentState)
      {
        console.log('player current update state', playerCurrentState)
      },

      // player is ready
      playerReadied(player)
      {
        console.log('the player is readied', player)
        // you can use it to do something...
        // player.[methods]
      },

      onPlayerTimeUpdate(player)
      {
        console.log(player)
      },

      onPlayerLoadedData(player)
      {
        console.log(player)
      },

      onPlayerCanPlay(player)
      {
        console.log(player)
      },

      onPlayerCanplayThrough(player)
      {
        console.log(player)
      },

      onPlayerWaiting(player)
      {
        console.log(player)
      }
    },

    mounted()
    {

    }
  }
</script>
