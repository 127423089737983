<template>
  <div :class="wrapperCss"
       v-focus-section>
      <h4 class="align-self-center">Condividi su :</h4>
      <ul class="social-inner d-block">
        <li v-for="social in socials" 
            :key="social"
            class="d-inline">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <ShareNetwork
                :network="social"
                :url="currentUrl"
                :title="show.title || appName"
                :description="show.excerpt"
              >
                <v-btn tile fab 
                     class="social-share-button"
                     v-bind="attrs"
                     v-on="on">
                <v-icon>mdi-{{social}}</v-icon>
              </v-btn>
            </ShareNetwork>
            </template>
            <span>CONDIVIDI SU {{ social.toUpperCase() }}</span>
          </v-tooltip>
        </li>
        <!-- simple copy URL -->
        <li class="d-inline">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn tile fab 
                     class="social-share-button"
                     v-bind="attrs"
                     v-on="on"
                     v-clipboard:copy="currentUrl"
                     v-clipboard:success="onCopy"
                     v-clipboard:error="onCopyError">
                <v-icon>mdi-link-variant</v-icon>
              </v-btn>
            </template>
            <span>COPIA LINK</span>
          </v-tooltip>
        </li>
      </ul>
  </div>
</template>

<style lang="scss" scoped>  
  .social-share-button {
    background-color: var(--dark-color) !important;
    
    &:hover {
      background-color: var(--primary-color) !important;
    }

  }
</style>

<script>
  import utility from '@/mixins/utility'

  import Show from '@/models/show'

  import SETTINGS from '@/plugins/settings'

  import { mapMutations } from 'vuex'

  export default {
    name: 'SocialShare',

    mixins: [ utility ],

    data: () => ({
      socials: [
        'facebook',
        'twitter',
        'linkedin'
      ]
    }),

    props: {
      show: {
        type: Show,
        default: null
      }
    },

    computed:
    {
      appName()
      {
        return SETTINGS.APP_NAME
      },

      currentUrl()
      {
        return this.show.shareUrl || ''
        //return location.href
      },

      wrapperCss()
      {
        let css = 'gen-socail-share mt-5'

        if( this.isMobile )
          css += ' text-center'

        return css
      }
    },

    methods:
    {
      ...mapMutations({
        growl: 'ui/growl'
      }),

      onCopy()
      {
        this.growl({
          content: 'Hai copiato la URL nei tuoi appunti.',
          color: 'success'
        })
      },

      onCopyError()
      {
        this.growl({
          content: 'Non sono riuscito a copiare la URL negli appunti.',
          color: 'error'
        })
      }
    }

  }
</script>
