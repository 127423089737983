<template>
    <span><!-- Stripe JS --></span>
</template>

<script>
import SETTINGS from '@/plugins/settings'

// Vuex.
import { mapActions } from 'vuex'

export default{
    props: {},
    mounted()
    {
        this.inject()
    },
    data()
    {
        return {}
    },
    computed: {
        
    },
    methods: {
        ...mapActions({
            'initStripe': 'ecommerce/initStripe'
        }),
        configure()
        {
            const apiKey = SETTINGS.STRIPE.apiKey,
                  Stripe = window.Stripe(apiKey)

            this.initStripe(Stripe)
        },
        inject()
        {
            const src = '//js.stripe.com/v3/'

            let script = document.createElement('script')
                script.setAttribute('src', src)
                script.addEventListener('load', this.configure)
                document.head.appendChild(script)
        }
    },
    watch: {
        
    }
}
</script>