<template>
  <v-row>

    <v-col :cols="isMobile ? 12 : 3" 
           class="pt-7">
      <span class="text-h5">Generale</span>
    </v-col>

    <v-col>

      <v-row>
        <v-col cols="9">
          <p>
            <strong class="primary--text">{{ user.fullname }}</strong>
          </p>
          <p>
            <v-icon class="mr-3" 
                    color="success"
                    v-if="user.emailIsVerified">
              mdi-check-circle
            </v-icon>
            <strong>{{ user.email }}</strong>
          </p>
          <p>Password: *********</p>
        </v-col>
        <v-col :class="isMobile ? 'text-center' : 'text-right'">
          <p>
            <v-btn block 
                   @click="editProfile"
                   :loading="isLoading">
              Aggiorna profilo
            </v-btn>
          </p>
        </v-col>
      </v-row>

    </v-col>
  </v-row>
</template>

<script>
import utility from '@/mixins/utility'

import { mapGetters } from 'vuex'

import ZWAN_STORE from '@/plugins/zwanStore'

export default {
  
  name: 'AccountGeneral',

  mixins: [ utility ],

  components: {
  },

  data: () => ({
    isLoading: false
  }),

  computed: {
    ...mapGetters({
      user: 'user',
    })
  },

  methods:
  {
    editProfile()
    {
      this.isLoading = true

      ZWAN_STORE.payload()
                .then(({ data })=>{
                  let state = data.payload,
                      url = ZWAN_STORE.url('profile', { state })

                  window.open(url)

                  this.isLoading = false
                })
    }
  },

  mounted() {

  }

}
</script>
