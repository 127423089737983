<template>
   <section class="pt-0 pb-0"
            v-focus-section>
      
      <carousel :dots="dots" 
                :nav="nav" 
                :items="2" 
                :center="true"
                :autoplay="isAutoplay" 
                :loop="loop" 
                :margin="30"
                :stagePadding="30"
                :autoWidth="true"
                class="carousel">
       
         <div class="carousel-item"
              v-for="(item,i) in items"
              :key="i"
              @click="goToShow(item)"
              :style="itemCss(item)">
        </div>
       
       </carousel>

   </section>
</template>

<style lang="scss">
  .carousel {

    .owl-dot {
      height: 0 !important;
    }

    .carousel-item {
      display: block;
      height: 30vh;
      background-size: cover;
      background-position: center;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px, rgb(0 0 0 / 73%) 0px 16px 10px -10px;
      cursor: pointer;
      transform: scale(1, 1) translateZ(0px);

      transition-duration: 300ms;
      transition-property: transform, box-shadow;
      transition-timing-function: ease-out;

      &:hover {
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px, var(--primary) 0px 16px 10px -10px;
        transform: scale(1.01, 1.01) translateZ(0px) translate3d(0px, 0px, 0px);
      }

      @media (max-width: 767px) {
        height: 100px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        height: 200px; 
      }
   }

  }
</style>

<script>
  import utility from '@/mixins/utility'

  import carousel from 'vue-owl-carousel'

  export default {
    name: 'Carousel',

    mixins: [utility],

    components: {
      carousel
    },

    data: () => ({
      
    }),

    props: {
      autoplay: {
        type: Boolean,
        default: true
      },
      dots: {
        type: Boolean,
        default: true
      },
      items: {
         type: Array,
         default: () => []
      },
      loop: {
        type: Boolean,
        default: true
      },
      nav: {
        type: Boolean,
        default: false
      }
    },

    computed:
    {
      isAutoplay()
      {
        if( !this.autoplay )
          return false

        if( this.items.length < 2 )
          return false

        return true
      }
    },

    methods:
    {
      goToShow(show)
      {
         this.goTo({ name:'show', params: { slug:show.slug } })
      },

      itemCss(item)
      {
        return {
          backgroundImage: `url(${item.slideshow})`,
          width: `80vw`,
          maxWidth: '1185px'
        }
      }
    }
  }
</script>
