const STRIPE = {
	apiKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY
}

const GA = {
	id: process.env.VUE_APP_GOOGLE_ANALYTICS
}

const FORM = {
	help: process.env.VUE_APP_FORM_HELP_ENDPOINT
}

const SETTINGS = {
	
	APP_NAME: process.env.VUE_APP_APP_NAME,

	GA,
	
	STRIPE,

	FORM,

	COOKIE_YES: process.env.VUE_APP_COOKIE_YES,

	get BACKEND_URL()
	{
		let url = process.env.VUE_APP_BACKEND_URL

		if( url.substr(-1) != '/' )
			url += '/'

		return url
	}
}

export default SETTINGS