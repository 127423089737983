<template>
  <div>

    <HeaderSpacer/>

    <v-container class="mt-10 mb-10">
      <h1 class="mt-7">Il tuo account</h1>

      <div v-for="(item,i) in items"
           :key="i" 
           class="px-10 py-10">
        <component :is="item.component" />
      </div>

    </v-container>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

import General from '@/components/account/AccountTabs/General'
import PaymentMethods from '@/components/account/AccountTabs/PaymentMethods'
import Privacy from '@/components/account/AccountTabs/Privacy'
import Subscription from '@/components/account/AccountTabs/Subscription'
import HeaderSpacer from '@/components/UI/HeaderSpacer'
import AccountManagement from '@/components/account/AccountTabs/AccountManagement'

export default {
  
  name: 'Account',

  mixins: [],

  components: {
    HeaderSpacer,
  },

  data: () => ({
    items: [
      { title: 'General', component:General },
      { title: 'Abbonamento', component:Subscription },
      { title: 'Metodi pagamento', component:PaymentMethods },
      { title: 'Privacy', component:Privacy },
      { title: 'Account Management', component: AccountManagement}
    ]
  }),

  computed: {
    /*
    ...mapGetters({
      shows: 'favourites',
    })
    */
  },

  methods:
  {
    ...mapActions({
      'refreshUserData': 'refreshUserData'
    })
  },

  mounted()
  {
    this.refreshUserData()
  }

}
</script>