<template>
  <v-row>

    <v-col :cols="isMobile ? 12 : 3" 
           class="pt-7">
      <span class="text-h5">I tuoi metodi di pagamento</span>
    </v-col>

    <v-col>
      
      <p class="text-center" v-if="isLoading">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </p>

      <div v-else>
        
        <v-alert v-if="!user.hasPaymentMethods" type="info">
          Non hai metodi di pagamento salvati.
        </v-alert>

        <v-row v-for="(method,i) in user.paymentMethods"
               :key="method.id">
          <v-col :cols="isMobile ? 12 : 9">
            <p v-if="method.isCard">
              
              <CreditCard :brand="method.card.brand" 
                          :height="isMobile ? 20 : 40"/>
              
              <span class="text-h6 ml-3">
                {{ method.cardNumber }}
              </span>
              
              <v-chip outlined 
                      color="primary" 
                      class="text-uppercase ml-3"
                      v-if="method.isDefault">
                Default
              </v-chip>

            </p>
            <p v-if="method.expiration">
              Scadenza <strong>{{ method.expiration.format('MM/Y') }}</strong>
              <span v-if="method.billing">
                <span class="mx-3">•</span>
                Intestata a <strong>{{ method.billing.name }}</strong>
              </span>
            </p>
          </v-col>
          <v-col cols="3" 
                 class="text-right"
                 v-if="!isMobile">

            <v-btn block v-if="i==0"
                   @click="editPaymentMethods">
              Gestisci
            </v-btn>        

          </v-col>
        </v-row>

        <v-row v-if="isMobile">
          <v-col cols="12" 
                 class="text-center">

            <v-btn block x-large
                   @click="editPaymentMethods">
              Gestisci
            </v-btn>        

          </v-col>
        </v-row>

      </div>

    </v-col>
  </v-row>
</template>

<script>
import API from '@/api'

import utility from '@/mixins/utility'

import { mapActions, mapGetters } from 'vuex'

import CreditCard from '@/components/ecommerce/CreditCardIcon'

import ZWAN_STORE from '@/plugins/zwanStore'

export default {
  
  name: 'AccountPaymentMethods',

  mixins: [ utility ],

  components: {
    CreditCard,
  },

  data: () => ({
    isLoading: false,
    methods: []
  }),

  computed: {
    ...mapGetters({
      user: 'user',
    })
  },

  methods:
  {
    ...mapActions({
      'refreshUserData': 'refreshUserData'
    }),

    loadPaymentMethods()
    {
      this.isLoading = true

      API.ecommerce.paymentMethods()
                   .then(({ data })=>{
                    
                    this.methods = this.mapModels(data.data, 'paymentMethod')

                    this.isLoading = false

                   })
    },
    
    editPaymentMethods()
    {
      this.isLoading = true

      ZWAN_STORE.payload()
                .then(({ data })=>{
                  let state = data.payload,
                      url = ZWAN_STORE.url('profile', { state })

                  window.open(url)

                  this.isLoading = false
                })
    }
  },

  mounted() {
    //this.loadPaymentMethods()
  }

}
</script>
