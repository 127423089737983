<template>
  <section class="py-5">
      <div class="container gen-style-2">

        <v-tabs v-model="currentSeasonTab" 
                active-class="primary white--text"
                background-color="transparent"
                :vertical="!isMobile"
                :class=" isMobile ? 'px-10' : '' ">
          
          <v-tab v-for="(serie,i) in series"
                 :key="i"
                 :class=" series.length > 1 ? '' : 'd-none' ">
            {{ serie.name }}
          </v-tab>

          <v-tabs-items v-model="currentSeasonTab"
                        :class=" isMobile ? 'mt-5' : 'px-10' ">
          
            <v-tab-item v-for="(serie,i) in series"
                        :key="i">
              
              <carousel :dots="false" 
                        :nav="nav" 
                        :items="cols" 
                        :autoplay="false" 
                        :loop="false" 
                        :margin="30">
                
                  <div class="item"
                       v-for="(show,i) in serie.episodes"
                       :key="i">
                    <ShowCard :show="show"/>
                  </div>

              </carousel>

            </v-tab-item>

          </v-tabs-items>

        </v-tabs>
   
      </div>
  </section>
</template>

<style type="text/css" scoped>
  .v-tabs-items {
    background-color: transparent !important;
  }
</style>

<script>
  import utility from '@/mixins/utility'

  import carousel from 'vue-owl-carousel'

  import ShowCard from '@/components/show/Card'

  export default {
    name: 'SeriesTab',

    mixins: [ utility ],

    mounted(){
      this.series = this.series.reverse()
    },

    components: {
      carousel,
      ShowCard
    },

    data: () => ({
      currentSeasonTab: null
    }),

    props: {
      nav: {
        type: Boolean,
        default: true
      },
      series: {
         type: Array,
         default: () => []
      }
    },

    computed:
    {
      cols()
      {
        return this.isMobile ? 2 : 3
      }
    }
  }
</script>
