<template>
  <v-row>

    <v-col :cols="isMobile ? 12 : 3" class="pt-7">
      <span class="text-h5">Abbonamento</span>
    </v-col>

    <v-col>

      <!-- if user has a subscription show details -->
      <div v-if="user.hasSubscription">
        <v-row v-for="subscription in user.subscriptions"
               :key="subscription.id">
          <v-col cols="9">
            <p v-if="subscription.plan"
               class="mb-0">
              <span class="text-h6">
                {{ subscription.plan.name }}
              </span>
              <v-chip outlined 
                      color="primary"
                      class="ml-3 text-uppercase">
                {{ subscription.status }}
              </v-chip>
            </p>
            <p v-if="subscription.price">
              {{ subscription.price.name }}
            </p>

            <v-alert color="warning" dense
                     v-if="subscription.hasEnded">
              Il tuo abbonamento &egrave; scaduto in data {{ subscription.endsAt.format('D/M/Y') }}.
            </v-alert>
            
            <v-alert color="warning" dense
                     v-else-if="subscription.isEnding">
              Il tuo abbonamento scadr&agrave; in data {{ subscription.endsAt.format('D/M/Y') }}.
            </v-alert>

          </v-col>

          <v-col cols="3">
            
            <v-btn block 
                   @click="manageSubscriptions"
                   :loading="isLoading">
              Gestisci
            </v-btn>

          </v-col>
        </v-row>
      </div>

      <!-- else show popup to subscribe a plan -->
      <div v-else>

        <v-row>
          <v-col :cols="isMobile ? 12 : 9">
            <v-alert color="warning">
              Non hai nessun abbonamento attivo.
            </v-alert>
          </v-col>
          <v-col :cols="isMobile ? 12 : 3" 
                 class="text-right">
            <v-btn block 
                   color="primary"
                   :x-large="isMobile"
                   @click="buySubscription"
                   :loading="isLoading">
              Abbonati ora
            </v-btn>
          </v-col>
        </v-row>
        
      </div>

    </v-col>
  </v-row>
</template>

<script>
import utility from '@/mixins/utility'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import ZWAN_STORE from '@/plugins/zwanStore'

export default {
  
  name: 'AccountSubscription',

  mixins: [ utility ],

  components: {

  },

  data: () => ({
    isLoading: false,
    isResuming: false,
    subscriptionPlansPopup: false
  }),

  computed: {
    ...mapGetters({
      user: 'user',
    })
  },

  methods:
  {
    ...mapActions({
      refreshUserData: 'refreshUserData'
    }),

    ...mapMutations({
      growl: 'ui/growl'
    }),

    manageSubscriptions()
    {
      this.isLoading = true

      ZWAN_STORE.payload()
                .then(({ data })=>{
                  let state = data.payload,
                      url = ZWAN_STORE.url('profile/subscriptions', { state })

                  window.open(url)

                  this.isLoading = false
                })
    },
    
    buySubscription()
    {
      this.isLoading = true

      ZWAN_STORE.payload()
                .then(({ data })=>{
                  let state = data.payload,
                      url = ZWAN_STORE.signup({ state })

                  window.open(url)

                  this.isLoading = false
                })
    },

  },

  watch:
  {
    
  },

  mounted() {

  }

}
</script>
