<template>
  <div class="pb-7">

    <v-btn tile x-large
      v-if="show.trailer"
      class="mr-4"
      @click="goTo({ name:'trailer', params: { slug:show.slug } })">
      TRAILER
    </v-btn>
                            
    <v-btn tile x-large
      color="primary" 
      @click="goToVideo"
      v-if="show.canPlay">
      <v-icon class="mr-2">mdi-play</v-icon>
      RIPRODUCI
    </v-btn>

    <v-btn tile x-large
      v-else-if="!user"
      @click="goTo('login')">
      ACCEDI
    </v-btn>

    <v-tooltip top v-else-if="!user.checkPlans(show.plans)">
      <template v-slot:activator="{ on, attrs }">
        <v-btn tile x-large color="success"
               v-bind="attrs"
               v-on="on"
               @click="subscribe"
               :loading="isLoading">
          ABBONATI
        </v-btn>
      </template>
      <span class="text-uppercase">
        Questo show non &egrave; compreso nel tuo abbonamento
      </span>
    </v-tooltip>

    <WatchlistButton :fab="true" 
                     :icon="false"
                     :show="show"
                     button-class="ml-5"/>

  </div>
</template>

<style type="text/css" scoped>
   
</style>

<script>
  import { mapGetters } from 'vuex'

  import utility from '@/mixins/utility'

  import Show from '@/models/show'

  import WatchlistButton from '@/components/watchlist/Button'

  import ZWAN_STORE from '@/plugins/zwanStore'

  export default {
    name: 'ShowHeaderButtonRow',

    mixins: [utility],

    components: {
      WatchlistButton
    },

    data: () => ({
      isLoading: false
    }),

    props: {
      show: {
        type: Show,
        default: null
      }
    },

    computed: {
      
      ...mapGetters({
        'user': 'user'
      }),

      videoHash()
      {
        return this.show.videoHash
      }

    },

    methods: {

      goToVideo()
      {
        let videoHash = this.videoHash

        this.goTo({ name:'video', params: { videoHash } })
      },

      subscribe()
      {
        this.isLoading = true

        ZWAN_STORE.payload()
                  .then(({ data })=>{
                    let state = data.payload,
                        url = ZWAN_STORE.signup({ state })

                    window.open(url)

                    this.isLoading = false
                  })
      }
    }
  }
</script>
