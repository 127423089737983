<template>
  <v-dialog width="500" v-model="status" >
    <v-card>

      <v-card-text class="py-7 red--text">
        Attenzione, l'azione non è reversibile! <br />  
        Per procedere è richiesta la conferma
      </v-card-text>

      <div class="p-4">
        <v-btn tile
               color="red"
               :disabled="isDeleting"
               @click="this.delete">
          {{ buttonLabel }}
        </v-btn> 
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: "UserDialog",

    data: () => ({
        status: false,
        isDeleting: false
    }),

    props: {
        buttonLabel: {
            type: String,
            default: 'Elimina account'
        },
        delete: {
            type: Function,
            default: () => {}
        }
    },

    methods:{
        show(){
            this.status = true
        },

        deleteUser(){
            if(this.isDeleting) return

            this.isDeleting = true

        }
    }
}
</script>