<template>
  <v-app-bar flat tile fixed class="video-header">
  
    <v-btn text @click="goBack">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-toolbar-title class="ml-3">
      <!-- serie details -->
      <small class="d-block grey--text"
             v-if="mainShow">
        {{ mainShow.title }} • 
        {{ show.serie.name }}
      </small>
      <!-- show title -->
      {{ show.title }}
    </v-toolbar-title>

  </v-app-bar>
</template>

<style lang="scss" scoped>
  .video-header {

  }
</style>

<script>
  import Show from '@/models/show'

  import utility from '@/mixins/utility'

  export default {
    name: 'VideoHeader',

    mixins: [utility],

    data: () => ({
      
    }),

    computed:
     {
       
    },

    props:
    {
      mainShow: {
        type: Show,
        default: null
      },
      show: {
        type: Show,
        default: null
      }
    },

    methods:
    {
      goBack()
      {
        this.goToSmart(this.show)
      }
    },

    mounted()
    {

    }
  }
</script>
