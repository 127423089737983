<template>
   <v-menu offset-y open-on-hover v-model="menu">
      <template v-slot:activator="{ on, attrs }">
         <v-btn fab small color="primary" 
                class="user-menu"
                v-bind="attrs"
                v-on="on"
                @focus="menu=true"
                @blur="menu=false">
            <v-icon>mdi-account</v-icon>
         </v-btn>
      </template>
      <v-list>
        
        <v-list-item @click="goTo('account')">
          <v-list-item-title>
             <v-btn text plain block>
                Il tuo account
             </v-btn>
          </v-list-item-title>
        </v-list-item>
        
        <!-- logout button -->
        <v-list-item @click="logout"
                     v-if="!isEmbed">
          <v-list-item-title>
             <v-btn text plain block color="error">
               <!-- <v-icon>mdi-logout</v-icon> -->
                Esci da {{ appName }}
             </v-btn>
          </v-list-item-title>
        </v-list-item>

      </v-list>
   </v-menu>
</template>

<style type="text/css" scoped>
   header#gen-header .gen-bottom-header .navbar .navbar-nav li .user-menu i {
      opacity: 1;
      margin-left: 0;
      font-size: 24px;
   }
</style>

<script>
  import utility from '@/mixins/utility'

  import { mapGetters } from 'vuex'

  export default {
    name: 'UserDropdown',

    mixins: [utility],

    data: () => ({
      menu: false
    }),

    computed: {
      ...mapGetters({
         isEmbed: 'spaces/isEmbed'
      }),
    },

    methods: {

      logout()
      {
         this.$store.dispatch('logout')
      },

      onFocus()
      {
         this.menu = true
      }

    },

    mounted()
    {

    }
  }
</script>
