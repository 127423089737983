<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn :icon="icon" 
             :fab="fab"
             @click.stop="toggle"
             v-bind="attrs"
             v-on="on"
             :class="buttonClass"
             v-if="isLogged">

          <v-progress-circular
            indeterminate
            color="primary"
            v-if="isLoading"
          ></v-progress-circular>
          
          <v-icon v-else-if="inWatchlist">mdi-check</v-icon>
          
          <v-icon v-else>mdi-plus</v-icon>

       </v-btn>
    </template>
    <span>
      <span v-if="inWatchlist">RIMUOVI DALLA TUA LISTA</span>
      <span v-else>AGGIUNGI ALLA TUA LISTA</span>
    </span>
  </v-tooltip>
</template>

<style type="text/css" scoped>
   
</style>

<script>
  import { mapGetters, mapMutations } from 'vuex'

  import watchlist from '@/mixins/watchlist'

  import Show from '@/models/show'

  export default {
    name: 'WatchlistButton',

    mixins: [watchlist],

    data: () => ({
      isLoading: false
    }),

    props: {
      buttonClass: {
        type: String,
        default: ''
      },
      fab: {
        type: Boolean,
        default: false
      },
      icon: {
        type: Boolean,
        default: true
      },
      show: {
        type: Show,
        default: ()=>new Show
      }
    },

    computed: {
      ...mapGetters({
        'isLogged': 'isLogged'
      }),
      inWatchlist()
      {
        return this.isFav(this.show.id)
      }
    },

    methods: {

      ...mapMutations({
        'growl': 'ui/growl'
      }),
      
      toggle()
      {
        this.isLoading = true

        this.toggleFav(this.show)
            .finally(()=>{
              this.isLoading = false
            })
      }
    }
  }
</script>
