<template>
  <section class="position-relative py-0">
      <div class="container">
  
        <div class="gen-tv-show-wrapper style-1">
          <div class="gen-tv-show-top">
              <div class="row">
                  
                  <div class="col-lg-6 align-self-center">
                      <div class="gen-single-tv-show-info">

                          <!-- Show details for episodes -->
                          <h4 v-if="mainShow"
                              class="mb-10 primary--text">
                            <p class="grey--text mb-0">
                              {{ show.serie.name }}
                            </p>
                            <span @click="goToSmart(mainShow)"
                                  class="pointer">
                              {{ mainShow.title }}
                            </span>
                          </h4>

                          <!-- Main title -->
                          <v-img :src="show.logo"
                                 v-if="show.logo"
                                 class="mb-5"/>
                          <h1 class="gen-title" v-else>
                            {{ show.title }}
                          </h1>

                          <!-- Show info -->
                          <InfoRow :show="show"/>

                          <p></p>

                          <h2 class="mb-5"
                              v-if="show.isPublishing">
                            Disponibile dal {{ show.publishedAt.format('D/MM/Y') }}
                          </h2>
                          
                          <div class="d-block mb-10" 
                               v-html="show.description"></div>

                          <ButtonRow :show="show"/>

                          <v-divider class="my-7"/>

                          <SocialShare :show="show" v-if="show"/>

                      </div>
                  </div>

              </div>
          </div>
        </div>

      </div>
  </section>
</template>

<style lang="scss" scoped>  
  .show-meta {
    list-style: none;
    font-family: var(--title-fonts);
    font-size: 18px;
    li {
      margin-bottom: 15px;
    }
  }
</style>

<script>
  import demo from '@/mixins/demo'
  import utility from '@/mixins/utility'

  import Show from '@/models/show'

  import ButtonRow from '@/components/show/Header/ButtonRow'
  import InfoRow from '@/components/show/Header/InfoRow'
  import SocialShare from './SocialShare'

  import { mapGetters } from 'vuex'

  export default {
    name: 'ShowHeader',

    mixins: [ demo, utility ],

    components: {
      ButtonRow,
      InfoRow,
      SocialShare
    },

    data: () => ({

    }),

    props: {
      mainShow: {
        type: Show,
        default: ()=>new Show
      },
      show: {
        type: Show,
        default: ()=>new Show
      }
    },

    computed:
    {
      ...mapGetters({
        'user': 'user'
      }),

      hasEpisodes()
      {
        return this.show.hasEpisodes
      },
      episodes()
      {
        return this.show.episodes
      },
      hasSeries()
      {
        return this.series.length > 0
      },
      series()
      {
        return this.show.series
      }
    },

    methods:
    {
      
    }

  }
</script>
