<template>
  <!-- footer start -->
   <footer id="gen-footer">
      <div class="gen-footer-style-1">
         <div class="gen-footer-top pb-10">

            <v-container>
               <v-row>

                  <!-- col #1 -->
                  <v-col :cols="cols">
                     <v-row :class="isMobile ? 'text-center': ''">
                        <v-col>
                           <Logo white :height="50"/>
                        </v-col>
                        <v-col cols="12">
                           <p>
                              <v-btn icon fab
                                 v-for="(social,i) in socials"
                                 :key="i"
                                 @click="popup(social.url)">
                               <v-icon>mdi-{{social.name}}</v-icon>
                              </v-btn>
                           </p>
                        </v-col>
                     </v-row>
                  </v-col>

                  <!-- col #2 -->
                  <v-col :cols="cols">
                     
                     <h4 class="mb-3">Company</h4>

                     <div class="widget">
                        <ul class="menu">
                           <li class="menu-item"
                               v-for="(link,i) in menu"
                               :key="i">
                              <router-link :to="link.route">
                                 {{ link.label }}
                              </router-link>
                           </li>
                        </ul>
                     </div>

                  </v-col>

                  <!-- col #3 -->
                  <v-col :cols="cols">
                     
                     <h4 class="mb-3">Download App</h4>
                     
                     <p>Guarda Business+ dove preferisci: browser, smartphone, tablet o Smart TV!</p>

                     <v-row class="text-center mt-7">
                        <!-- App Store -->
                        <v-col>
                           <v-btn text 
                                  @click="clickOnDevices"
                                  target="_blank">
                              <AppStore/>
                           </v-btn>   
                        </v-col>
                        <!-- Google Play -->
                        <v-col>
                           <v-btn text 
                                  @click="clickOnDevices"
                                  target="_blank">
                              <GooglePlay/>
                           </v-btn>   
                        </v-col>
                     </v-row>

                     <v-row class="text-center mt-7">
                        <!-- LG -->
                        <v-col>
                           <v-btn text 
                                  @click="clickOnDevices"
                                  target="_blank">
                              <LG class="store-logo"/>
                           </v-btn>   
                        </v-col>
                        <!-- Samsung -->
                        <v-col>
                           <v-btn text 
                                  @click="clickOnDevices"
                                  target="_blank">
                              <Samsung class="store-logo"/>
                           </v-btn>   
                        </v-col>
                        <!-- Amazon Fire TV -->
                        <v-col>
                           <v-btn text 
                                  @click="clickOnDevices"
                                  target="_blank">
                              <FireTV class="store-logo"/>
                           </v-btn>   
                        </v-col>
                     </v-row>

                  </v-col>

               </v-row>
            </v-container>

         </div>
         <div class="gen-copyright-footer">
            <div class="container">
               <div class="row">
                  <div class="col-md-12 align-self-center">
                     <span class="gen-copyright">
                        <a target="_blank" href="https://www.zwan.it">
                           &copy; Zwan. Tutti i diritti riservati.
                        </a>
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </footer>
   <!-- footer End -->
</template>

<style type="text/css" scoped>
   footer#gen-footer .gen-footer-style-1 .widget ul.menu li {
      width: 100%;
   }
   svg.store-logo {
      fill:  #fff;
      transform: scale(4);
   }
</style>

<script>
   import Logo from '@/components/UI/Logo'

   // store icons.
   import AppStore from '@/assets/images/icons/app-store.svg'
   import FireTV from '@/assets/images/icons/fire-tv.svg'
   import GooglePlay from '@/assets/images/icons/google-play.svg'
   import LG from '@/assets/images/icons/lg.svg'
   import Samsung from '@/assets/images/icons/samsung.svg'

   import { mapGetters } from 'vuex'

   import utility from '@/mixins/utility'

  export default {
    name: 'Footer',

    mixins: [ utility ],

    components:
    {
      Logo,
      // store icons.
      AppStore,
      FireTV,
      GooglePlay,
      LG,
      Samsung
    },

    data: () => ({
      socials: [
         //{ name:'facebook', url:'#' },
         //{ name:'twitter', url:'#' },
         //{ name:'instagram', url:'#' },
         //{ name:'youtube', url:'#' },
      ]
    }),

    computed:
    {
      ...mapGetters({
         isLogged: 'isLogged',
      }),

      menu()
      {
         let menu = [
            { label:'Chi siamo', route:{name:'about'} },
            { label:'Informativa sulla privacy', route:{name:'privacy'} },
            //{ label:'Policy sui cookie', route:{name:'home'} },
            { label:'Condizioni generali di abbonamento', route:{name:'terms'} },
            { label:'Centro assistenza', route:{name:'help'} },
         ]

         if( !this.isLogged )
            menu.unshift({ label:'Iscriviti a Business+', route:{name:'signup'} })

         return menu
      },

      cols()
      {
         return this.isMobile ? 12 : 4
      }
    },

    methods:
    {
      popup(url)
      {
         window.open(url)
      },
      clickOnDevices()
      {
         this.goTo({ name: 'about', hash:'#devices' })
      }
    },

    load(){}
  }
</script>
