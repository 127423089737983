<template>
   <div class="cover">
      <div class="cover-image"
           v-if="video">
        <video-player class="video-player-box"
                      ref="videoPlayer"
                      :options="playerOptions"
                      :playsinline="true"
                      customEventName="customstatechangedeventname"/>
      </div>
      <div class="cover-image" 
           :style="coverImage"
           v-else></div>
      <div class="cover-overlay"></div>
   </div>
</template>

<style lang="scss" scoped>
  .cover {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .cover-image {
      position: absolute;
      width: 100%;
      height: 100vh;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-transition: opacity .1s;
      transition        : opacity .1s;
    }
    .cover-overlay {
      background-image: radial-gradient(farthest-side at 73% 21%, transparent, rgb(26, 29, 41));
      position: absolute;
      inset: 0px;
    }
  }
  
  /**
   * Mobile.
   */
  
  @media (max-width: 767px) {
  
    .cover {
      position: relative;
      height: 30vh;
      
      .cover-image {
        height: 30vh;
      }

      .cover-overlay {
        background: linear-gradient(to bottom, rgba(10, 14, 23, 0) 0%, rgba(10, 14, 23, 0) 60%, rgba(10, 14, 23, 1) 90%);

        position: absolute;
        inset: 0px;
      }

    }
  
  }
</style>

<style lang="scss">
  @media (max-width: 767px) {
    .cover {
      .cover-image {
        .video-player-box {
          .video-js {
            height: 30vh !important;
          }
        }
      }
    }
  }
</style>

<script>
  import utility from '@/mixins/utility'

  import { videoPlayer } from 'vue-video-player'

  export default {
    name: 'Cover',

    mixins: [ utility ],

    components: {
      videoPlayer
    },

    data: () => ({
      
    }),

    computed:
     {
       coverImage()
       {
         return {
           backgroundImage: `url(${this.image})`,
           opacity: this.opacity
         }
       },

       playerOptions()
       {
        /**
         * @see https://docs.videojs.com/tutorial-options.html
         */
        return {
          // videojs options
          autoplay: true,
          muted: true,
          loop: true,
          language: 'it',
          controls: false,
          liveui: true,
          //playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: this.show.bgVideo
          }],
          poster: this.show.bg,
        }
       }

    },

    props:
    {
      image:
      {
         type: String,
         default: ()=>""
      },
      opacity:
      {
        type: Number,
        default: 0.9
      },
      show:
      {
         type: Object,
         default: null
      },
      video:
      {
        type: Boolean,
        default: false
      }
    },

    methods:
    {
      bindScroll()
      {
        let cover = document.querySelector('.cover-image')

        if(cover)
         document.addEventListener('scroll',()=>{

         var windowHeight = window.innerHeight,
             scrollTop = document.documentElement.scrollTop,
             scrolled = (windowHeight - scrollTop * 2.5) / windowHeight,
             opacity = this.opacity * scrolled

             // imposto una opacità minima
             if( opacity < 0.5 )
               opacity = 0.3

             cover.style.opacity = opacity

         })
      }
    },

    mounted()
    {
      if( !this.isMobile )
        this.bindScroll()
    }
  }
</script>
