<template>
  <v-row>

    <v-col :cols="isMobile ? 12 : 3" class="pt-7">
      <span class="text-h5">Privacy</span>
    </v-col>

    <v-col>

      <Loading :status="isLoading" absolute/>

      <!-- TOC -->
      <v-row>
        <v-col cols="9">
          <p>
            <strong>Condizioni generali</strong>
          </p>
          <div v-if="user.hasToc">
            <p>
              Hai accettato le condizioni generali per l'utilizzo della piattaforma in data {{ user.tocAt.format('DD/MM/Y') }} alle ore {{ user.tocAt.format('HH:mm') }}.
            </p>
          </div>
        </v-col>
        <v-col class="text-right" cols="3">

        </v-col>
      </v-row>

      <!-- marketing consense -->
      <v-row v-if="marketing !== null">
        <v-col cols="9">
          
          <p>
            <strong>Consenso a ricevere informazioni commerciali</strong>
          </p>

          <div>
            <p v-if="user.hasMarketing">
              Hai dato il tuo consenso per ricevere informazioni commerciali in data {{ user.marketingAt.format('DD/MM/Y') }} alle ore {{ user.marketingAt.format('HH:mm') }}.
            </p>
            <p v-else>
              Non hai ancora dato il tuo consenso per ricevere comunicazioni commerciali.
            </p>
          </div>

          <v-radio-group v-model="marketing" row mandatory>
            <v-radio
              label="Acconsento"
              :value="true"
            ></v-radio>
            <v-radio
              label="Non acconsento"
              :value="false"
            ></v-radio>
          </v-radio-group>

        </v-col>
        <v-col class="text-right" cols="3">
          
        </v-col>
      </v-row>

    </v-col>
  </v-row>
</template>

<script>
import utility from '@/mixins/utility'

import API from '@/api'

import { mapGetters, mapActions, mapMutations } from 'vuex'

import Loading from '@/components/UI/Loading'

export default {
  
  name: 'AccountPrivacy',

  mixins: [ utility ],

  components: {
    Loading
  },

  data: () => ({
    isLoading: false,
    marketing: null
  }),

  computed: {
    ...mapGetters({
      user: 'user',
    })
  },

  methods:
  {
    ...mapMutations({
      'growl': 'ui/growl'
    }),
    ...mapActions({
      'refreshUserData': 'refreshUserData'
    })
  },

  watch:
  {
    user:
    {
      deep: true,
      handler(user)
      {
        this.marketing = user.hasMarketing
      }
    },

    marketing(newValue, oldValue)
    {
      if(oldValue === null)
        return

      let data = {
        email: this.user.email,
        id: this.user.id
      }

      this.isLoading = true

      if(newValue)
        API.signup.marketing(data)
                  .then(()=>{
                    
                    this.refreshUserData()
                        .then(()=>this.isLoading = false)

                    this.growl({
                      content: `Abbiamo registrato il tuo consenso.`
                    })

                  })

      else
        API.signup.revokeMarketing(data)
                  .then(()=>{
                    
                    this.refreshUserData()
                        .then(()=>this.isLoading = false)

                    this.growl({
                      content: `Abbiamo rimosso il tuo consenso.`
                    })

                  })
    }
  },

  mounted() {

  }

}
</script>
