<template>
  <v-app-bar flat tile fixed 
    id="main-header"
    v-scroll="onScroll">
      
    <!-- logo -->
    <Logo white v-bind="logoSettings"/>

    <v-spacer/>

    <v-app-bar-nav-icon 
      @click="onDrower"
      v-if="isMobile"/>

    <div v-else>
      
      <HeaderLink icon="home" @click="goTo('home')">
        HOME
      </HeaderLink>

      <HeaderLink icon="magnify" @click="goTo('search')">
        CERCA
      </HeaderLink>

      <HeaderLink icon="television" @click="goTo('watch')">
        GUARDA ORA
      </HeaderLink>

      <HeaderLink icon="plus" @click="goTo('watchlist')"
                  v-if="isLogged"
                  class="mr-7">
        LA MIA LISTA
      </HeaderLink>

      <UserDropdown v-if="isLogged"/>

      <v-btn @click="goTo('login')" tile large color="primary"
             v-if="!isLogged && !isEmbed">
        ACCEDI
      </v-btn>

    </div>

  </v-app-bar>
</template>

<style lang="scss" scoped>
   #main-header {
      background-color: transparent;
      background: -moz-linear-gradient(top,var(--dark-color) 0%, transparent 100%);
      background: -webkit-linear-gradient(top,var(--dark-color) 0%, transparent 100%);
      background: linear-gradient(to bottom,var(--dark-color) 0%, transparent 100%);

      &.v-app-bar--is-scrolled {
        z-index: 9999;
        background-color: var(--dark-color);
      }
   }
</style>

<script>
  import utility from '@/mixins/utility'

  import { mapGetters } from 'vuex'

  import Logo from '@/components/UI/Logo'
  import HeaderLink from '@/components/UI/HeaderLink'
  import UserDropdown from '@/components/UI/UserDropdown'

  export default {
    name: 'Header',

    mixins: [utility],

    components: {
      HeaderLink,
      Logo,
      UserDropdown
    },

    data: () => ({
      isScrolled: false
    }),

    computed: {
      ...mapGetters({
        isEmbed: 'spaces/isEmbed',
        isLogged: 'isLogged',
      }),

      logoSettings()
      {
        return {
          height: 50,
          class: '',
          style: 'transform: scale(0.7) translateX(-30px)'
        }
      }
    },

    methods:
    {
      onDrower()
      {
        this.$emit('drower')
      },

      onScroll()
      {
        this.isScrolled = ( document.documentElement.scrollTop > 0 )
      }
    }
  }
</script>
