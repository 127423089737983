<template>
  <v-row>

    <v-col :cols="isMobile ? 12 : 3" class="pt-7">
      <span class="text-h5">Eliminare Account?</span>
    </v-col>

    <v-col>

      <!-- else show popup to subscribe a plan -->
        <p class="red--text">
            <strong>Una volta eliminato non sarà più possibile accedere a Business Plus</strong>
        </p>
        
      <v-btn @click="$refs.userDialog.show()">
        Elimina account
      </v-btn>
      
    </v-col>
    
    <UserDialog ref="userDialog" :delete="deleteUser"/>
    
  </v-row>
</template>

<script>
import utility from '@/mixins/utility'
import UserDialog from '@/components/account/UserDialog'

import { mapActions, mapGetters, mapMutations } from 'vuex'

//import API from '@/api'


export default {
    name: 'AccountManagement',
    components: {
        UserDialog
    },

  mixins: [ utility ],

  data: () => ({
    isLoading: false,

  }),

  computed: {
    ...mapGetters({
      user: 'user',
    })
  },

  methods:
  {
    ...mapActions({
      refreshUserData: 'refreshUserData',
      deleteUser: 'deleteUser'
    }),

    ...mapMutations({
      growl: 'ui/growl'
    }),
      
  },
}
</script>