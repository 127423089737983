<template>
  <v-btn text class="mx-2"
         @click="onClick">
    <v-icon v-if="icon" class="mr-2" color="grey" >mdi-{{ icon }}</v-icon>
    <slot></slot>
  </v-btn>
</template>

<style type="text/css" scoped>
   
</style>

<script>
  export default {
    name: 'HeaderLink',

    mixins: [],

    components: {},

    data: () => ({
      
    }),

    props: {
      icon: {
        type: String,
        default: ''
      }
    },

    methods: {
      onClick()
      {
        this.$emit('click')
      }
    }
  }
</script>
